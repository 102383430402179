<template>
  <div class="main-content" id="content">
    <h3 class="marginBottom20">
      {{ `${type === "add" ? "新增" : type === "edit" ? "编辑" : "查看"}QA` }}
    </h3>
    <!-- <el-card style="padding-left: 50px"> -->
    <el-form
      ref="form"
      :model="form"
      label-position="left"
      label-width="80px"
      :rules="rules"
    >
      <el-form-item label="问答标题" prop="title">
        <el-input
          v-model="form.title"
          placeholder="请输入"
          style="width: 400px"
          maxlength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="问答内容" :span="8" prop="content">
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入内容"
          style="width: 600px"
          v-model="form.content"
          maxlength="100"
          show-word-limit
        >
        </el-input>
      </el-form-item>
      <el-form-item label="内容" :span="8" prop="way">
        <FormEditor
          :details="form.way"
          @editoChange="editoChange"
          :disabled="disabled"
        />
      </el-form-item>

      <el-form-item>
        <el-button @click="revert">返回</el-button>
        <el-button type="primary" @click="submit" v-if="!disabled"
          >确认</el-button
        >
      </el-form-item>
    </el-form>
    <!-- </el-card> -->
  </div>
</template>

<script>
import { qaManageAdd, qaManageUpdate, qaManageView } from "@/api/qa/manage";
import FormEditor from "@/components/formComponents/formEditor";
import dayjs from "dayjs";

export default {
  name: "create",
  components: {
    FormEditor,
  },
  data() {
    return {
      classifyList: [],
      id: this.$route.query.id,
      disabled: this.$route.query.type === "view",
      type: this.$route.query.type,
      form: {
        content: "",
        title: "",
        way: "",
      },
      //校验规则
      rules: {
        title: [{ required: true, message: "请输入", trigger: "blur" }],
        content: [{ required: true, message: "请输入", trigger: "blur" }],
        way: [
          {
            required: true,
            message: "请输入权益内容",
            trigger: "blur",
          },
          {
            validator(_, value) {
              if (value) {
                if (value === "<p><br></p>") {
                  return Promise.reject(new Error("请输入内容"));
                }
                if (value.length > 65535) {
                  return Promise.reject(new Error("内容超出最大长度限制"));
                }
              }
              return Promise.resolve(true);
            },
          },
        ],
      },
      loadingSubmit: false,
    };
  },
  mounted() {
    if (this.type == "edit") {
      this.getView();
    }
  },
  methods: {
    async getView() {
      if (this.id) {
        const { data } = await qaManageView(this.id);
        this.form = {
          ...data,
        };
      }
    },

    submit() {
      if (this.loadingSubmit) {
        return;
      }

      let newVal = {
        ...this.form,
      };

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loadingSubmit = true;

          if (this.type == "add") {
            qaManageAdd(newVal)
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code == 200) {
                  this.loadingSubmit = false;
                  this.$router.go(-1);
                } else {
                  this.loadingSubmit = false;
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                this.loadingSubmit = false;
              });
          } else {
            qaManageUpdate({
              ...newVal,
              id: this.id,
            })
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code == 200) {
                  this.loadingSubmit = false;
                  this.$router.go(-1);
                } else {
                  this.loadingSubmit = false;
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                // loading.close();
                // this.$message.error(err.msg);
                this.loadingSubmit = false;
              });
          }
        }
      });
    },
    //富文本框变化替换值
    editoChange(e) {
      this.form.way = e;
    },
    revert() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.main-content {
  padding-left: 30px;
  padding-right: 40%;
}
</style>
